import React, { useCallback, useState } from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid, Typography, Collapse } from '@mui/material'
import { TextField, MenuSelectorField, AutocompleteField, DateTimeField, ModelAutocompleteField, ModelMenuSelectorField } from '@front/squirtle'

import CPUtils from '@front/squirtle/utils/CPUtils'
import { UserUtils } from '@front/volcanion/utils'


const Row1 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs><TextField name={'order_client_id'} label={I18n.t('order.id.alter')} /></Grid>
    <Grid item xs><TextField name={'do.auth.gsm'} label={I18n.t('phone.label', { count: 1 })} /></Grid>
    <Grid item xs><TextField name={'do.customerinfo.client_number'} label={I18n.t('user.id')} /></Grid>
    <Grid item xs><TextField name={'do.customerinfo.name'} label={I18n.t('designation')} /></Grid>
    <Grid item xs><TextField name={'do.info.first_name'} label={I18n.t('name.first_name', { count: 1 })} /></Grid>
    <Grid item xs><TextField name={'do.info.last_name'} label={I18n.t('name.label', { count: 1 })} /></Grid>
  </Grid>

const Row2 = ({ company, contract }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <ModelAutocompleteField
        name={'company'}
        label={I18n.t('company.label', { count: 1 })}
        model_name={'company'}
        searchMode='search'
        searchQuery={'startsWith'}
        autocompleteMutator={q => ({
          or: [{ client_id: { startsWith: q } }, { name: { startsWith: q } }]
        })}
        labelKeys={['label']}
        config={{
          mutator: company => ({ label: `${_.get(company, 'client_id')} - ${_.get(company, 'name')}`, ...company })
        }}
        loadOnFocus={false}
        debounceTime={400}
      />
    </Grid>
    <Grid item xs>
      <ModelAutocompleteField
        name={'contract'}
        label={I18n.t('contract.name_client_id')}
        model_name={'companycontract'}
        searchMode='search'
        searchQuery={'startsWith'}
        autocompleteMutator={q => ({
          or: [{ client_id: { startsWith: q } }, { name: { startsWith: q } }]
        })}
        labelKeys={['label']}
        config={{
          forced_filter: { company },
          validate_filter: useCallback(() => !!company, [company]),
          watchers: [company],
          mutator: companycontract => ({ label: `${_.get(companycontract, 'client_id')} - ${_.get(companycontract, 'name')}`, ...companycontract })
        }}
        loadOnFocus={false}
        debounceTime={400}
      />
    </Grid>
    <Grid item xs >
      <ModelAutocompleteField
        name={'service'}
        label={I18n.t('companyservice.code')}
        model_name={'companyservice'}
        searchMode='search'
        searchQuery={'startsWith'}
        autocompleteMutator={q => ({
          or: [{ client_id: { startsWith: q } }, { name: { startsWith: q } }]
        })}
        labelKeys={['label']}
        config={{
          forced_filter: { contract },
          validate_filter: useCallback(() => !!contract, [contract]),
          watchers: [contract],
          mutator: companyservice => ({ label: `${_.get(companyservice, 'client_id')} - ${_.get(companyservice, 'name')}`, ...companyservice })
        }}
        loadOnFocus={false}
        debounceTime={400}
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name='commercial_formula'
        label={I18n.t('commercial_formula.label', { count: 1 })}
        model_name='commercialformula'
        labelKeys={['name']}
        searchMode={'search'}
        loadOnMount
        multiple
        clearOnEmpty
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name='commercial_package.commercial_package_id'
        label={I18n.t('commercialpackage.label', { count: 2 })}
        model_name='commercialpackage'
        labelKeys={['label']}
        config={{
          mutator: cp => ({ ...CPUtils.getTranslatedLabel(cp), ...cp })
        }}
        searchMode={'search'}
        loadOnMount
        multiple
        clearOnEmpty
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name='channel'
        label={I18n.t('channel.label', { count: 1 })}
        model_name='domain'
        labelKeys={['name']}
        searchMode={'search'}
        config={{ forced_filter: { user_type: { '!': 'driver' } } }}
        loadOnMount
        multiple
        clearOnEmpty
      />
    </Grid>
  </Grid>


const Row3 = ({ order_status_list, transport_status_list }) => {
  const orderStatusOptions = _.map(order_status_list, (status) => ({ label: I18n.t(`order.status.${status}`), value: status }))
  const transportStatusOptions = _.map(transport_status_list, (status) => ({ label: I18n.t(`transport.status.${status}`), value: status }))
  return <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'mode'}
        label={I18n.t('ride.type')}
        model_name={'ridemode'}
        searchMode={'search'}
        labelKeys={['name_translated']}
        config={{ sort: ['display_index ASC'] }}
        loadOnMount
        multiple
      />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        name='status'
        label={I18n.t('order.status.label', { count: 1 })}
        options={orderStatusOptions}
        multiple
        clearOnEmpty
      />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        name='assigned_transport.status'
        label={I18n.t('transportStatus.label', { count: -1 })}
        options={transportStatusOptions}
        multiple
        clearOnEmpty
      />
    </Grid>
    <Grid item xs><TextField name={'loads.src_contact'} label={I18n.t('transport.passenger')} /></Grid>
    <Grid item xs>
      <ModelAutocompleteField
        name={'assigned_transport.driver'}
        label={I18n.t('driver.search_multiple')}
        model_name={'user'}
        searchMode='search'
        searchQuery={'='}
        autocompleteMutator={q => ({
          or: [
            { info: { user_client_id: { startsWith: _.split(q, ' ') } } },
            { info: { first_name: { startsWith: _.split(q, ' ') } } },
            { info: { last_name: { startsWith: _.split(q, ' ') } } }]
        })}
        labelKeys={['label']}
        multiple={false}
        loadOnFocus={false}
        config={{
          forced_filter: { driverinfo: { '!': null } },
          populate: ['info', 'customerinfo'],
          mutator: user => ({
            label: UserUtils.getFormatedName({ ...user?.info, designation: user?.customerinfo?.name }, { prefix: user?.info?.user_client_id }),
            ...user
          })
        }}
        selectSingleOption
        debounceTime={400}
      />
    </Grid>
    <Grid item xs><TextField name='assigned_transport.vehicle.info.plaque' label={I18n.t('vehicle.label', { count: 1 })} /></Grid>
  </Grid>
}

const Row4 = ({ billing_status_list }) => {
  const billingStatusOptions = _.map(billing_status_list, (status) => ({ label: I18n.t(`billing.status.${status}`), value: status }))
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs><TextField name={'source.name'} label={I18n.t('address.source')} /></Grid>
      <Grid item xs>
        <ModelMenuSelectorField
          name={'payment_type'}
          label={I18n.t('bank.payment_mode')}
          model_name={'paymenttype'}
          searchMode={'search'}
          config={{ sort: ['display_index ASC'] }}
          labelKeys={['name_translated']}
          loadOnMount
          multiple
          clearOnEmpty
        />
      </Grid>
      <Grid item xs>
        <MenuSelectorField
          name='billing_status'
          label={I18n.t('billing.status.label', { count: 1 })}
          options={billingStatusOptions}
          multiple
          clearOnEmpty
        />
      </Grid>
      <Grid item xs>
        <TextField name='customer_ref' label={I18n.t('bank.b2c.customer_ref')} />
      </Grid>
      <Grid item xs={2} >
        <TextField name='order_doc' label={I18n.t('document.b2c.id')} />
      </Grid>
      <Grid item xs>
        <ModelAutocompleteField
          name='operator'
          label={I18n.t('operator', { count: 1 })}
          model_name='user'
          searchMode='search'
          searchQuery='startsWith'
          autocompleteMutator={q => ({
            auth: { username: { startsWith: q } }
          })}
          labelKeys={['label']}
          config={{
            forced_filter: { staffinfo: { '!': null } },
            mutator: user => ({
              label: UserUtils.getFormatedName(user?.info, { prefix: user?.auth?.username }),
              ...user
            }),
            populate: ['auth', 'info']
          }}
          loadOnFocus={false}
          selectSingleOption
          debounceTime={400}
        />
      </Grid>
    </Grid>
  )
}

const Row5 = props =>
  <Grid container item sx={12} spacing={1}>
    <Grid item xs>
      <MenuSelectorField
        name={'schedule_type'}
        label={I18n.t('order.schedule_type')}
        allowNone={false}
        multiple
        options={[
          { label: I18n.t('order.planned.label', { count: 1 }), value: "planned" },
          { label: I18n.t('order.immediate.label', { count: 1 }), value: "immediate" }
        ]}
      />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        name={'reason'}
        label={I18n.t('reason.label', { count: 1 })}
        multiple
        allowNone
        noneLabel={I18n.t('operator.label', { count: 1 })}
        options={[
          { label: I18n.t('event.keys.not_loaded'), value: "not_loaded" },
          { label: I18n.t('event.keys.late_cancel'), value: "late_cancel" },
          { label: I18n.t('event.keys.not_assigned'), value: "not_served" },
          { label: I18n.t('event.keys.client'), value: "client" },
          { label: I18n.t('event.dispatched'), value: "dispatch" },
          { label: I18n.t('event.keys.janitor'), value: "janitor" },
        ]}
      />
    </Grid>
    <Grid item xs>
      <DateTimeField
        name='requestedAt_start'
        dateProps={{
          label: I18n.t('date.search'),
          inputFormat: 'DD/MM/YYYY',
        }}
        timeProps={{
          label: I18n.t('prefix.at'),
          minTime: null
        }}
        default_time='00:00'
      />
    </Grid>
    <Grid item xs>
      <DateTimeField
        name='requestedAt_end'
        dateProps={{
          label: I18n.t('prefix.to'),
          inputFormat: 'DD/MM/YYYY'
        }}
        timeProps={{
          label: I18n.t('prefix.at'),
          minTime: null
        }}
        default_time='23:59'
      />
    </Grid>
  </Grid>

const SearchForm = ({ initial_toggle, ...rest }) => {
  const [toggle, setToggle] = useState(initial_toggle)
  return (
    <Box sx={{
      boxShadow: 3,
      color: 'primary.main',
      '&:hover': !!toggle ? undefined : {
        backgroundColor: 'primary.main',
        color: 'extraWhite'
      },
    }}
    >
      <Box onClick={() => setToggle(!toggle)}>
        <Box sx={{ m: 1, p: 1 }}>
          <Typography variant={'h5'} color='inherit'> {I18n.t('order.search')} </Typography>
        </Box>
      </Box>
      <Collapse in={!!toggle}>
        <Box p={1}>
          <Grid container rowSpacing={5}>
            <Grid container item justifyContent={'center'} spacing={1}>
              <Row1 {...rest} />
              <Row2 {...rest} />
              <Row3 {...rest} />
              <Row4 {...rest} />
              <Row5 {...rest} />
            </Grid>
          </Grid>
        </Box >
      </Collapse>
    </Box >
  )
}

export default React.memo(SearchForm)
